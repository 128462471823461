<template>
  <div>
    <vs-table :data="fakeData">
      <template slot="thead">
        <vs-th>No</vs-th>
        <vs-th>Date</vs-th>
        <vs-th>Total KG</vs-th>
        <vs-th>Price</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :style="index % 2 == 1 ? 'background-color: #f3f3f3' : ''"
          class="orderRow"
          :data="order"
          :key="order.id"
          v-for="(order, index) in data"
        >
          <vs-td>
            <div @click="OpenDetailPage(order.id)">
              {{ order.id }}
            </div>
          </vs-td>
          <vs-td>
            <div @click="OpenDetailPage(order.id)">
              {{ order.date }}
            </div>
          </vs-td>
          <vs-td>
            <div @click="OpenDetailPage(order.id)">{{ order.totalKg }}</div>
          </vs-td>
          <vs-td>
            <div @click="OpenDetailPage(order.id)">{{ order.price }}$</div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <!-- PAGINATION EKLENECEK -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      fakeData: [
        {
          id: 1,
          date: "12.12.2021",
          totalKg: 5.5,
          price: 120,
        },
        {
          id: 2,
          date: "12.11.2021",
          totalKg: 3,
          price: 70,
        },
        {
          id: 3,
          date: "2.11.2021",
          totalKg: 2,
          price: 35,
        },
        {
          id: 4,
          date: "6.11.2021",
          totalKg: 4,
          price: 90,
        },
        {
          id: 5,
          date: "15.11.2021",
          totalKg: 1,
          price: 10,
        },
      ],
    };
  },
  methods: {
    OpenDetailPage(id) {
      this.$router
        .push({
          name: "shop_order",
          query: { no: id },
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
  },
};
</script>

<style scoped>
.orderRow {
  font-size: 1.1rem;
  font-weight: 500;
}
</style>
